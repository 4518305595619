import BigNumber from '@exchange/helpers/bignumber';

import { ORDER_NUMBERS } from './interfaces';
import type { PriceLevel, OrderbookSide } from './interfaces';
import type { UpdateListener } from './update-listener';
import { findIndex } from './util';

export interface AnimatedPriceLevel extends PriceLevel {
  updatedAt: number;
  removedAt: number;
  addedAt: number;
}

export class Side {
  public priceLevels: Array<AnimatedPriceLevel> = [];

  public side: OrderbookSide;

  public updateListeners: Array<UpdateListener> = [];

  constructor(side: OrderbookSide) {
    this.side = side;
  }

  public addUpdateListener(listener: UpdateListener) {
    this.updateListeners.push(listener);

    if (listener.snapshot) {
      listener.snapshot(this.priceLevels);
    }

    return () => {
      this.updateListeners = this.updateListeners.filter((l) => l !== listener);
    };
  }

  public getBest(amount: number): Array<PriceLevel> {
    return this.side === 'ASK' ? this.priceLevels.slice(0, amount).reverse() : this.priceLevels.slice(0, amount);
  }

  public addSnapshot(snapshot: Array<[string, string]>) {
    this.priceLevels = [];
    snapshot.forEach(([price, size]) => {
      this.addUpdate(
        +price,
        +size,
        {
          index: this.priceLevels.length,
          exists: false,
        },
        true,
      );
    });
    this.updateListeners.forEach((listener) => listener.snapshot(this.priceLevels));
  }

  public addUpdate(price: number, amount: number, { index, exists } = findIndex(price, this.priceLevels, this.side), isSnapshot = false): boolean {
    const existsForReal = exists && this.priceLevels[index]?.removedAt === 0;

    const newAmount = new BigNumber(existsForReal ? this.priceLevels[index]?.amount ?? 0 : 0).plus(amount).toNumber();

    const newPriceLevel: PriceLevel = {
      side: this.side,
      price,
      amount: newAmount,
      total: new BigNumber(price).times(newAmount).toNumber(),
    };

    if (newAmount === 0) {
      if (exists) {
        // eslint-disable-next-line max-len
        // If a pricelevel gets added and removed again very quickly the updated buffer will only send the remove action for a price that has not been added - so we need to not remove anything in this case
        this.remove(index);

        if (!isSnapshot) {
          this.updateListeners.forEach((listener) => listener.removed(newPriceLevel, index, this.priceLevels));
        }
      }
    } else if (exists) {
      this.update(index, newPriceLevel);

      if (!isSnapshot) {
        this.updateListeners.forEach((listener) => listener.updated(newPriceLevel, index, this.priceLevels));
      }
    } else {
      this.add(index, newPriceLevel);

      if (!isSnapshot) {
        this.updateListeners.forEach((listener) => listener.added(newPriceLevel, index, this.priceLevels));
      }
    }

    if (index <= ORDER_NUMBERS) {
      return true;
    }

    return false;
  }

  public addUpdateAgr(agrPrice: number, price: number, amount: number, { index, exists } = findIndex(agrPrice, this.priceLevels, this.side)): void {
    const newSize = new BigNumber(exists ? this.priceLevels[index]?.amount || 0 : 0).plus(amount).toNumber();

    if (newSize === 0) {
      if (exists) {
        // eslint-disable-next-line max-len
        // If a pricelevel gets added and removed again very quickly the updated buffer will only send the remove action for a price that has not been added - so we need to not remove anything in this case
        this.remove(index);
      }
    } else if (exists) {
      const newTotal = new BigNumber(this.priceLevels[index]?.total || 0).plus(new BigNumber(price).times(amount)).toNumber();

      this.update(index, {
        side: this.side,
        price: agrPrice,
        amount: newSize,
        total: newTotal,
      });
    } else {
      this.add(index, {
        side: this.side,
        price: agrPrice,
        amount: newSize,
        total: new BigNumber(price).times(newSize).toNumber(),
      });
    }
  }

  public add(index: number, priceLevel: PriceLevel): void {
    const pl = {
      ...priceLevel,
      addedAt: performance.now(),
      removedAt: 0,
      updatedAt: 0,
    };

    if (index === 0) {
      this.priceLevels.unshift(pl);
    } else if (index === this.priceLevels.length) {
      this.priceLevels.push(pl);
    } else {
      this.priceLevels.splice(index, 0, pl);
    }
  }

  public remove(index: number): void {
    // if (!force && index < 50) {
    //   this.priceLevels[index].removedAt = performance.now();
    // } else
    if (index === 0) {
      this.priceLevels.shift();
    } else if (index === this.priceLevels.length - 1) {
      this.priceLevels.pop();
    } else {
      this.priceLevels.splice(index, 1);
    }
  }

  public update(index: number, priceLevel: PriceLevel): void {
    const pl = this.priceLevels[index];

    if (!pl) {
      return;
    }

    pl.amount = priceLevel.amount;
    pl.total = priceLevel.total;

    if (index < 50) {
      pl.updatedAt = performance.now();
      pl.removedAt = 0;
    }
  }
}
