import identityResource from './identity';

const API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
const API_SUFFIX = 'personal/api';
const API_VERSION = 'v1';
const API_BASE = `${API_DOMAIN}/${API_SUFFIX}/${API_VERSION}`;

export const oAuthRest = {
  Identity: identityResource({ base: API_BASE }),
};

export default oAuthRest;
