import rest from '@exchange/libs/utils/rest/src';

const URL = '/identity';

export default function Resource({ base }) {
  return {
    get(): Promise<{ addressOfResidency: { country: string } }> {
      return rest.request({
        url: URL,
        base,
      });
    },
  };
}
