import AccountResources from './account';
import BalancesResource from './balances-resource';
import CurrenciesResource from './currencies-resource';
import CustomerResource from './customer';
import FeesResource from './fees-resource';
import InstrumentsResource from './instruments-resource';
import MarketStatsResource from './market-stats';
import MarketTickerResource from './market-ticker-resource';
import OrderBookResource from './order-book-resource';
import SubaccountsResource from './subaccounts';
import TimeResource from './time-resource';

const API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
const API_FAST_DOMAIN = process.env.VUE_APP_API_DOMAIN_FAST;
const API_SUFFIX = 'public';
const API_VERSION = 'v1';
const API_BASE = `${API_DOMAIN}/${API_SUFFIX}/${API_VERSION}`;
const API_FAST_BASE = `${API_FAST_DOMAIN}/${API_VERSION}`;

const exchangeRest = {
  Account: { ...AccountResources({ base: API_FAST_BASE }) },
  Balances: BalancesResource({ base: API_FAST_BASE }),
  Currencies: CurrenciesResource({ base: API_FAST_BASE }),
  Customer: CustomerResource({ base: API_FAST_BASE }),
  Fees: FeesResource({ base: API_FAST_BASE }),
  Instruments: InstrumentsResource({ base: API_FAST_BASE }),
  MarketStats: MarketStatsResource({ base: API_FAST_BASE }),
  MarketTicker: MarketTickerResource({ base: API_FAST_BASE }),
  OrderBook: OrderBookResource({ base: API_FAST_BASE }),
  Subaccounts: { ...SubaccountsResource({ base: API_BASE, baseFast: API_FAST_BASE }) },
  Time: TimeResource({ base: API_FAST_BASE }),
};

export default exchangeRest;
